@import '../styles/utils.scss';


.time-filters {


    .transactions-number {
        color: rgba(36, 70, 71, 1);
        background: linear-gradient(270deg, rgba(0, 182, 155, 0.5) 0%, rgba(3, 131, 112, 0) 100%);
        width: 195px;
        height: 50px;
        border-radius: 5px;
        font-weight: $fw-semi-bold;
        line-height: 24px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }


    .time-selector {}

    .dropdown-header {
        background: rgba(255, 255, 255, 1);
        width: 150px;
        color: rgba(36, 70, 71, 1);
    }

    .app-dropdown-container .dropdown-list .dropdown-option {
        padding: 10px 20px;
        padding-right: 20px;
        color: rgba(36, 70, 71, 1);
        font-weight: $fw-semi-bold;
    }

    .filter-button {
        background: rgba(255, 255, 255, 1);
        color: rgba(153, 153, 153, 1);
        height: 50px;
        padding: 0px 20px;
        font-size: 15px;
        font-weight: $fw-bold;
        line-height: 22.5px;
        border-radius: 5px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        cursor: pointer;

        &.active {
            color: rgba(255, 255, 255, 1);
            @include accent-gradient-background;
        }
    }
}