@import '../../../../styles//utils.scss';


.transaction-details-page {
    
    .page-header {

        .back-icon {
            cursor: pointer;
            transition: transform 0.3s ease, color 0.3s ease;

            &:hover {
                transform: translateX(-5px);

            }

            &:active {
                transform: translateX(-5px) scale(0.95);
            }
        }

        .status {
            margin-top: 10px;
            padding: 0px 5px;
            color: rgba(255, 255, 255, 1);
            height: 34px;
            font-size: 20px;
            font-weight: $fw-bold;
            letter-spacing: 0.5px;
            text-align: center;
            border-radius: 5px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);


            &.approved {
                background: linear-gradient(90deg, #00B69B 44.5%, #038370 100%);
            }

            &.refunded {
                background: linear-gradient(90deg, #6A5CA8 0%, #3B316A 100%);
            }

            &.chargeback {
                background: linear-gradient(90deg, #32ADE6 0%, #1C6080 100%);
            }

            &.error {
                background: linear-gradient(90deg, #EF1E1B 33%, #AE0D0A 100%);
            }

            &.declined {
                background: linear-gradient(90deg, #FF9500 29%, #D17C05 100%);
            }

            &.pending {
                background: linear-gradient(90deg, #5C4033 0%, #3B2B20 100%);
            }
        }
    }


    .inner-page {
        box-sizing: border-box;
        padding: 0px 30px 40px 60px;

        .progress-bar-chart {
            height: 30px;

            .fill {
                height: 30px;
            }
        }

    }
}