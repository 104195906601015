@import 'src/styles/utils';

.app-form-element-label {
  padding-bottom: 8px;
  color: $color-text-label;
  font-size: 12px;
  font-weight: $fw-bold;

  &.auth {
    color: #282828;
    font-size: 16px;
    font-weight: $fw-semi-bold;
  }

}