@import '../../../../styles/utils.scss';


.step-1-page {
    width: 100%;
    min-height: 100vh;
    background-color: #EFEFEF;
    padding: 40px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    @media #{$lt-500-devices} {
        padding: 40px 20px;
    }

    .title {
        font-size: 48px;
        font-weight: bold;
        color: #00B69B;

        letter-spacing: 1px;
    }

    .loader-container {
        min-height: 50%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .step-up-iframe {
        width: 500px;
        height: 500px;
        border: none;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);


        @media #{$lt-500-devices} {
            width: 80%;
            height: 550px;
        }
    }
}