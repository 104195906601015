@import '../../../../styles/utils.scss';

.mids-page {
    .page-subtitle {
        color: #999999;
        margin-bottom: 10px;
        line-height: 23px;
        letter-spacing: 0.5px;
    }

    .total-chart {
        @media #{$lt-500-devices} {
            flex-direction: column;
        }
    }
}