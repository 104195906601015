@import 'src/styles/utils';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
  font-size: 16px;
  gap: 10px;
  font-weight: $fw-bold;
  cursor: pointer;


  &.auth {
    font-size: 20px;
    border-radius: 5px;
    border: none;
    background: linear-gradient(90deg, #51F6CB 0%, #00B69B 50.5%);
    color: #244647;
    font-weight: $fw-bold;
    box-shadow:
      0px 6px 12px rgba(0, 0, 0, 0.2),
      inset 0px -2px 4px rgba(0, 0, 0, 0.1);

    // &:disabled {
    //   background:  $color-bg-blue-disabled;
    //   border: 1px solid  $color-bg-blue-disabled;
    // }
  }

  &.accent {
    justify-content: space-evenly;
    min-width: auto;
    border-radius: 5px;
    height: 30px;
    min-width: 120px;
    @include accent-gradient-background;
    // border: 1px solid $color-accent;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: $fw-semi-bold;
    box-shadow: 0px 4px 4px 0px #00000040;

    &:disabled {
      background: linear-gradient(90deg, #D9EAE7 0%, #CED5D8 100%);
      // border: 1px solid  $color-bg-blue-disabled;
    }
  }

  &.secondary {
    justify-content: space-evenly;
    min-width: auto;
    border-radius: 5px;
    height: 30px;
    min-width: 120px;
    background-color: #33384C26;
    border: none;
    color: #244647;
    font-size: 14px;
    font-weight: $fw-semi-bold;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  &.form {
    height: 50px;
    width: 100px;
    min-width: 100px;
    border-radius: 12px;
    background-color: $color-accent;
    color: #FFFFFF;
    border: 0px;

    &:disabled {
      cursor: default;
      background-color: #323D4E;
      border: 0.6px solid;
      color: $color-text-label
    }

  }

  &.filters-button {
    @include accent-gradient-background;
    padding: 0px 10px;
    height: 30px;
    // box-sizing: border-box;
    min-width: auto;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 12px;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px #00000040;


    &:hover {
      filter: brightness(1.15);
    }

    span {
      text-wrap: nowrap;
    }

    &.disabled {
      background-color: #2d3748;
      color: #a0aec0;
      cursor: not-allowed;
      border: 1px solid #a0aec0;
    }
  }

  &.form-outline {
    height: 50px;
    width: 100px;
    min-width: 100px;
    border-radius: 12px;
    background-color: transparent;
    color: $color-accent;
    border: 1px solid $color-accent;
  }

  &.outline-light {
    background: transparent;
    border: 1px solid $color-text-light;
    color: $color-text-light;
  }

  &.download {
    background-color: #FFFFFF;
    height: 30px;
    font-size: 13px;
    font-weight: $fw-semi-bold;
    line-height: 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 4px 4px 0px #00000040;

  }
}