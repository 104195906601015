@import 'src/styles/utils';

.app-form-radio-group {
  height: 100%;

  .radio-group-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;

    .radio-option-container {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      .radio-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: $color-bg-form-field;
        border: 1px solid  $color-bg-form-field;

        .radio-checked {
          display: none;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: $color-text-form-field;
        }


        &.checked {
          border: 1px solid  $color-bg-form-field;

          .radio-checked {
            display: flex;
          }
        }

      }
      .radio-text-container {
        display: flex;
        flex-direction: column;

      
        .radio-text {
          flex: 1 1 0%;
          font-size: 13px;
          color: $color-text-form-field;
          font-weight: $fw-semi-bold;
          margin: 0;
         
        }
  
        .radio-secondary-text {
          color: $color-text-placeholder;
          font-weight: $fw-normal;
        }
      }
    }
  }
}





