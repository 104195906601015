@import '../../../../styles/utils.scss';

.transaction-details-part-payment-information {
    padding: 30px 0px;

    .section-subtitle {
        color:  rgba(153, 153, 153, 1);
        font-size: 15px;
        font-weight: $fw-semi-bold;
        line-height: 22.5px;

    }
}