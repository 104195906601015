@import './styles/common-styles';
@import './styles/utils';

html,
body,
#root {
}

body {
  margin: 0;
  font-family: 'Poppins',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


