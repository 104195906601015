@import '../styles/utils.scss';

.progress-bar-chart {
    width: 100%;
    min-width: 400px;
    min-height: 20px;
    background: #33384C26;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;

    &.total {
        box-shadow: 0px 4px 4px 0px #00000040;
    }

    .fill {
        min-height: 20px;
        background: linear-gradient(90deg, #00B69B 44.5%, #038370 100%);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: end;
        color: white;
        font-weight: $fw-semi-bold;
        width: 0; 
        animation: customFill 0.5s ease-in-out forwards; 

        &.total {
            min-height: 30px;
            background: linear-gradient(266.15deg, #244647 5.85%, #218579 94.56%);
            color: white;
            font-weight: $fw-bold;
            animation: customFillTotal 0.7s ease-in-out forwards;
        }
    }

    .label {
        margin-right: 2px;
        position: absolute;
        font-size: 14px;
        color: white;
        z-index: 1;
        font-weight: bold;
        opacity: 0;
        animation: fadeIn 0.5s ease forwards 0.5s; 

        &.small-label {
            font-size: 11px; 
            font-weight: $fw-semi-bold;
        }

        &.extra-small-label {
            font-size: 8px; 
            font-weight: $fw-semi-bold;
        }
    }
}

@keyframes customFill {
    from {
        width: 0;
    }
    to {
        width: var(--custom-percent); 
    }
}

@keyframes customFillTotal {
    from {
        width: 0;
    }
    to {
        width: var(--custom-percent); 
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
