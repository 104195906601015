.bar-chart {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;

    .bar-row {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 10px;

        .bar-label {
            width: 100px;
            font-size: 16px;
            font-weight: bold;
            color: #282828;
            text-align: left;
        }

        .bar {
            display: flex;
            flex-grow: 1;
            height: 30px;
            background-color: #f0f0f0;
            border-radius: 5px;
            overflow: hidden;
        }

        .bar-segment {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: bold;
            color: #fff;
            white-space: nowrap;
            width: 0; 
            transition: width 0.8s ease-in-out;
        }

        .segment-label {
            padding: 0 5px;
        }
    }
}
