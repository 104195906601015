.commission-item {
    position: relative;
    flex: 40%;
    max-width: 450px;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px 0px #00000040;

    .label {
        font-size: 14px;
        font-weight: 600;
        line-height: 15px;
        text-decoration-skip-ink: none;
        @media (max-width: 1400px) {
            display: flex;
            flex-direction: column;
}
    }

    .commision-field-value {
        width: 20%;
        .app-form-input {
            .app-input {
                padding: 10px 5px 10px 5px;
            }
        }
    }

    .commission-field-anyvalue {
        display: flex;
        align-items: flex-end;
    }
    .dotted {
        width: 100%;
        border: none;
        border-top: 2px dashed #C2C3C5;
    }

    .remove-icon {
        cursor: pointer;
        position: absolute;
        right: 5px;
    }
}