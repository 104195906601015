@import '../styles/utils.scss';

.section-container {
    width: 100%;
    background-color: #fff;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    box-sizing: border-box;
}
