@import 'src/styles/utils';

.app-textarea-container {
    width: 100%;
  
  .app-textarea {
    background-color: #F8F8F8;
    width: 100%;
    padding: 10px 30px;
    border-radius: 12px;
    border: 1px solid #CFCFCF;
    font-size: 16px;
    color: #333;
    font-weight: $fw-normal;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    resize: none; 
    box-sizing: border-box;
    outline: none;

    &::-webkit-scrollbar {
        width: 8px;
        border-radius: 20px;
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
      }
  
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::placeholder{
        color: #979797;
        font-size: 16px;
        font-weight: $fw-normal;
      }

  }
 
}

  