@import '../../../../styles/utils.scss';

.transaction-details-part-transaction-information {

    margin-bottom: 25px;

    .item {
        width: 300px;
        height: 60px;
        padding: 0px 10px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        &.entity {
            background: rgba(51, 56, 76, 0.15);
        }

        .primaryText {
            color: rgba(9, 9, 9, 1);
            font-weight: $fw-semi-bold;
            line-height: 24px;
        }

        .secondaryText {
            color: rgba(36, 70, 71, 1);
            font-size: 13px;
            line-height: 19.5px;
            opacity: 50%;
        }

        &.action {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .action-date {
                position: absolute;
                top: 50%;
                right: -180px;
                transform: translateY(-50%);
                z-index: 3;


                @media #{$lt-500-devices} {
                    display: none;
                }


                &.error {
                    top: 50%;
                    right: -120px;
                    transform: translateY(-50%);
                }

                .title {
                    font-weight: $fw-semi-bold;
                    // text-wrap: nowrap;
                }

                .date {
                    font-size: 13px;
                    font-weight: $fw-normal;
                    color: #B3B3B3;
                    text-wrap: nowrap;
                }
            }

            &.approved {
                border: 2px dotted #00B69B;
                background: rgba(0, 182, 155, 0.05);

                .primaryText {
                    color: #00B69B;
                }

                .action-date .title {
                    color: #00B69B;
                }
            }

            &.chargeback {
                border: 2px dotted #32ADE6;
                background: rgba(106, 92, 168, 0.1);

                .primaryText {
                    color: #32ADE6;
                }

                .action-date .title {
                    color: #32ADE6;
                }
            }

            &.refunded {
                border: 2px dotted #6A5CA8;
                background: rgba(106, 92, 168, 0.1);

                .primaryText {
                    color: #6A5CA8;
                }

                .action-date .title {
                    color: #6A5CA8;
                }
            }

            &.declined {
                border: 2px dotted #FF9500;
                background: rgba(239, 30, 26, 0.05);

                .primaryText {
                    color: #FF9500;
                }

                .action-date .title {
                    color: #FF9500;
                }
            }

            &.pending {
                border: 2px dotted #5C4033;
                background-color: rgba(239, 30, 26, 0.05);

                .primaryText {
                    color: #5C4033;
                }

                .action-date .title {
                    color: #5C4033;
                }
            }

            &.error {
                position: relative;
                border: 2px dotted #EF1E1A;
                background: rgba(255, 255, 255, 1);

                .primaryText {
                    color: #EF1E1A;
                }

                .action-date .title {
                    color: #EF1E1A;
                }

                .info-container {
                    padding: 10px;
                    right: 350px;
                    top: 0px;
                    border-radius: 5px;
                    width: 250px;
                    background: rgba(239, 30, 26, 0.05);
                    position: absolute;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

                    .title {
                        color: #EF1E1B;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        text-align: left;
                    }

                    .description {
                        color: rgba(40, 40, 40, 1);
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 19.5px;
                        text-align: justified;
                        opacity: 80%;
                    }
                }
            }

            .info-container {
                right: 350px;
                top: 10px;
                position: absolute;

                @media #{$lt-500-devices} {
                    display: none;
                }

                .title {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;

                    &.error {
                        color: #EF1E1B;
                    }

                    &.declined {
                        color: #FF9500;
                    }

                    &.approved {
                        color: #00B69B;
                    }

                    &.chargeback {
                        color: #32ADE6;
                    }

                    &.refunded {
                        color: #6A5CA8;
                    }

                    &.pending {
                        color: #5C4033;
                    }
                }

                .description {
                    flex-wrap: wrap;
                    color: rgba(40, 40, 40, 1);
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 19.5px;
                    text-align: justified;
                    opacity: 80%;
                    display: flex;
                    gap: 15px;
                }
            }

        }
    }

    .info-container-mobile {
        margin-top: 30px;

        @media #{$lt-1080-devices} {
            display: none;
        }

        @media #{$lt-500-devices} {
            display: block;
        }
    }

    .action-date-mobile {
        margin: 20px 0;



        @media #{$lt-1080-devices} {
            display: none;
        }

        @media #{$lt-500-devices} {
            display: flex;
        }

        .title {
            font-weight: $fw-semi-bold;

            &.error {
                color: #EF1E1B;
            }

            &.declined {
                color: #FF9500;
            }

            &.approved {
                color: #00B69B;
            }

            &.chargeback {
                color: #32ADE6;
            }

            &.refunded {
                color: #6A5CA8;
            }

            &.pending {
                color: #5C4033;
            }
        }

        .date {
            font-size: 13px;
            font-weight: $fw-normal;
            color: #B3B3B3;
            text-wrap: nowrap;
        }
    }

    &.hidden {
        visibility: hidden;
        display: block;
    }

    .separator {
        width: 2px;
        height: 80px;
        margin: 0 auto;
        border-left: 3px dotted rgba(0, 0, 0, 0.2);
    }
}