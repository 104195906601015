.transactions-part-filtered-data-item {
    padding: 20px 30px;
    min-height: 158px;
    box-sizing: border-box;
    background: linear-gradient(270deg, #FFFFFF 45.5%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .description {
        color: rgba(36, 70, 71, 1);
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0.5px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        .value {
            font-size: 17px;
            font-weight: 700;
            line-height: 23px;
            letter-spacing: 0.5px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        }
    }

    .button.accent {
        @media (max-width: 1300px) {
            height: 35px;
        }
    }

    .downloading-text {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #ff6f00; 
        text-align: center;
    }
}
