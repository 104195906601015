@import 'variables';
@import 'mixins';
@import 'flexbox';

/**
 * Layout
 */
.page-content {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px 20px;
  gap: 40px;
  background-color: #EFEFEF;
  overflow: auto;

  @media #{$lt-500-devices} {
    min-height: calc(100vh - 45px);
  }

  .page-title {
    font-size: 48px;
    font-weight: 800;
    letter-spacing: 0.30000001192092896px;
    background: linear-gradient(266.15deg, #244647 5.85%, #218579 94.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    
    .page-subtitle {
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0.30000001192092896px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }

  

  .table-header {

    display: flex;
    justify-content: space-between;
    align-items: center;

    .table-title {
      font-size: 20px;
      font-weight: $fw-bold;
      color: #FFFFFF;
    }
  }
}

.page-outer {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 20px 20px;
  background-color: #EFEFEF;
  overflow: auto;
}

.page-outer {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .page-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    // max-width: $content-max-width;
    padding: 0px;

   
  }

  @media #{$sm-devices} {
    padding-bottom: 60px;
  }
}

.dashboard-page-content {
  display: flex;
  gap: 30px;

  >* {
    flex: 1 1 0%;
  }
}

/**
 * Misc
 */
.text-error {
  color: $color-error;
}

.text-accent {
  color: $color-accent;
}

.accent-gradient-text {
  @include accent-gradient-text
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}


/**
 * Forms
 */
.form-element-width {
  width: 100%;
  max-width: $form-element-max-width;
}

.form-buttons-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-top: 22px;
  width: 100%;
  max-width: $form-element-max-width;

  @media #{$sm-devices} {
    flex-direction: column;
    justify-content: flex-start;

    >* {
      width: 100%;
    }

    &.row {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &.row {
    @extend .flex-row;
  }
}

/**
 * Show / Hide
 */
.hide-md {
  @media #{$md-devices} {
    display: none !important;
  }
}

.hide-gt-md {
  @media #{$gt-md-devices} {
    display: none !important;
  }
}

.hide-sm {
  @media #{$sm-devices} {
    display: none !important;
  }
}


.md-flex-wrap {
  @media #{$md-devices} {
    flex-wrap: wrap;
  }
}

.sm-flex-wrap {
  @media #{$sm-devices} {
    flex-wrap: wrap;
  }
}