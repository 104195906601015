@import '../styles/utils.scss';

.legend-item {
    min-width: 150px;
    .color {
        width: 27px;
        height: 13px;
        gap: 0px;
        border-radius: 20px;
        opacity: 0px;
    }

    .name {
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 0.5px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

    }

    .type {
        color: #607d8b;
        font-size: 18px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0.5px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

    }

    .label {
        font-size: 14px;
        letter-spacing: 0.5px;

    }

    .value {
        @include accent-gradient-text;
        font-weight: $fw-bold;


        &.quantitative-value  {
            font-size: 13px;
        }

        &.qualitative-value {
            font-size: 15px;
        }
    }

    .bolded {
        font-weight: $fw-semi-bold;
    }
}