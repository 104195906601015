@import 'src/styles/utils';

.app-input {
  &.hasIcon {
    gap: 10px;
  }

  &.filter {
    height: 50px;
    background: rgba(255, 255, 255, 1);
    padding: 0px 15px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    input {

    }
  }

  input {
    font-family: inherit;
    font-size: 14px;
    background: transparent;
    border: none;
    outline: none;
    padding-left: 0px;
    color: $color-text-form-field;
    width: 100%;
    font-weight: $fw-bold;

    &::placeholder {
      font-size: 15px;
      font-weight: $fw-semi-bold;
      line-height: 22.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: $color-text-label;
    }

    &[type="number"] {
      -moz-appearance: textfield; 
      -webkit-appearance: none;  
      appearance: none;          

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; 
      }
    }
  }

  button {
    margin: 5px 0px;

    &.button {
      font-weight: $fw-normal;
    }
  }

  &.auth {
    background: #F4F4F4;
    border-radius: 5px;

    input {
      font-size: 16px;
      background: #F4F4F4;
      border: none;
      outline: none;
      color: #282828;
      width: 100%;
      font-weight: $fw-normal;

      &::placeholder {
        color: #979797;
        ;
      }
    }
  }
}