@import '../../../../styles/utils.scss';

.reports-daily-mobile-day-info {
    .card {
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 50px 16px;
        margin: 16px;
        background-color: #fff;
        box-shadow: 0px 4px 4px 0px #00000040;
        display: flex;
        gap: 30px;
        flex-direction: column;

        &.fade-in {
            animation: fadeIn 0.3s ease-in-out;
        }

        .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;

            .card-title {
                font-size: 18px;
                font-weight: bold;
            }

            .arrow-btn {
                background-color: transparent;
                border: none;
                cursor: pointer;

                &:disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }

                svg {
                    width: 24px;
                    height: 24px;
                    fill: #000;

                    &.left {
                        transform: rotate(-90deg);
                    }

                    &.right {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .card-body {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .merchant-data {
                margin-bottom: 16px;
                display: flex;
                flex-direction: column;
                gap: 15px;


                p {
                    margin: 4px 0;
                    font-size: 17px;

                    &.merchant-name {
                        font-size: 20px;

                    }

                    .value {
                        font-size: 19px;
                        color: $color-text-form-field;
                        font-weight: $fw-semi-bold;

                        &.approved {
                            color: rgb(0, 182, 155);
                        }

                        &.declined {
                            color: rgb(239, 30, 26);
                        }
                    }
                }
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}