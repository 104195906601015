@import '../../styles/utils.scss';

.app-layout {
    height: 100%;
    position: relative;
    display: flex;
    
    @media #{$lt-500-devices} {
        flex-direction: column;
    }

    .mobile-header {
        display: none;

        @media #{$lt-500-devices} {
            // height: 0px;
            padding: 10px 20px;
            display: flex;
            gap: 30px;
            @include accent-gradient-background;

            .header-title {
                font-size: 24px;
                color: aliceblue;
            }
        }
    }

    .side-menu-placeholder {
        min-width: 260px;
        min-height: 100vh;
        @media #{$lt-500-devices} {
            display: none;
        }
    }
}