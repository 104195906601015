@import 'src/styles/utils';

.app-dropdown-container {
  position: relative;
  max-width: 450px;
  font-size: 16px;

  &.filter {
    .dropdown-header {
      background: rgba(255, 255, 255, 1);
      width: 200px;
      color: rgba(36, 70, 71, 1);

      .placeholder {
        color: rgba(153, 153, 153, 1);

      }
    }

    .dropdown-list .dropdown-option {
      padding: 10px 20px;
      padding-right: 20px;
      color: rgba(36, 70, 71, 1);
      font-weight: $fw-semi-bold;
    }
  }

  &.status {

    .dropdown-header {
      padding-right: 10px;
      color: rgba(255, 255, 255, 1);
      height: 25px;
      font-size: 13px;
      font-weight: $fw-bold;
      letter-spacing: 0.5px;
      border-radius: 5px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      cursor: pointer;

      transition: filter 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        filter: brightness(1.15);
        box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.35); 
      }

      &.approved {
        background: linear-gradient(90deg, #00B69B 44.5%, #038370 100%);
      }

      &.refund {
        background: linear-gradient(90deg, #6A5CA8 0%, #3B316A 100%);
      }

      &.chargeback {
        background: linear-gradient(90deg, #32ADE6 0%, #1C6080 100%);
      }
      &.rdr {
        background: linear-gradient(90deg, #32ADE6 0%, #1C6080 100%);
      }
      &.pending {
        background: linear-gradient(90deg, #5C4033 0%, #3B2B20 100%);
      }

      &.error {
        background: linear-gradient(90deg, #EF1E1B 33%, #AE0D0A 100%);
      }

      &.declined {
        background: linear-gradient(90deg, #FF9500 29%, #D17C05 100%);
      }

      .placeholder {
       
      }


      .dropdown-arrow {
        color: rgba(255, 255, 255, 1);
      }
    }

    .dropdown-list {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      text-align: start;
      color: rgba(255, 255, 255, 1);
      background-color: #F9F9F9;
      border-radius: 5px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      max-height: 200px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      .dropdown-option {
        padding: 8px 10px;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.2s;

        &.approved {
          background: linear-gradient(90deg, #00B69B 44.5%, #038370 100%);

          &:hover {
            background: linear-gradient(90deg, #00D6B5 44.5%, #04A389 100%);
          }
        }

        &.refunded {
          background: linear-gradient(90deg, #6A5CA8 0%, #3B316A 100%);

          &:hover {
            background: linear-gradient(90deg, #8578C2 0%, #4D3F80 100%);
          }
        }

        &.chargeback {
          background: linear-gradient(90deg, #32ADE6 0%, #1C6080 100%);

          &:hover {
            background: linear-gradient(90deg, #4BC3F0 0%, #287CA0 100%);
          }
        }
        &.rdr {
          background: linear-gradient(90deg, #32ADE6 0%, #1C6080 100%);

          &:hover {
            background: linear-gradient(90deg, #4BC3F0 0%, #287CA0 100%);
          }
        }

        &.error {
          background: linear-gradient(90deg, #EF1E1B 33%, #AE0D0A 100%);

          &:hover {
            background: linear-gradient(90deg, #F5413F 33%, #C0120F 100%);
          }
        }

        &.declined {
          background: linear-gradient(90deg, #FF9500 29%, #D17C05 100%);

          &:hover {
            background: linear-gradient(90deg, #FFAB33 29%, #E68A0A 100%);
          }
        }
      }
    }
  }

  .dropdown-header {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: $fw-bold;
    color: $color-text-form-field;
    background: $color-bg-form-field;
    box-shadow: 0px 4px 4px 0px #00000040;

    .icon-label-container {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .dropdown-arrow {
      justify-self: flex-end;
      transition: transform 0.3s;
      transform: rotate(0deg);

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    color: $color-text-label;
    background-color: #F9F9F9;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .dropdown-option {
      padding: 10px 35px;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: #EDEDED;
      }
    }
  }
}