@import '../../../styles//utils.scss';

.transactions-part-data-item {
    padding: 20px 30px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    &.success {
        background: linear-gradient(270deg, rgba(0, 182, 155, 0.5) 0%, rgba(0, 80, 68, 0) 100%);
    }

    &.error {
        background: linear-gradient(270deg, rgba(236, 34, 31, 0.5) 0%, rgba(134, 19, 17, 0) 100%);
    }

    .description {

        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0.5px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        @media (max-width: 1300px) {
                    display: flex;
                    flex-direction: column;
        }

        &.success {
            color: rgba(36, 70, 71, 1);

        }

        &.error {
            background: linear-gradient(90deg, #EF1E1B 33%, #AE0D0A 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .value {
            font-size: 17px;
            font-weight: 700;
            line-height: 23px;
            letter-spacing: 0.5px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
    
        }
    }
    
}