@import '../styles/utils.scss';

.app-modal-transaction-logs-overlay {
  position: fixed;
  padding: 20px 0px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  // align-items: center;
  z-index: 1000;
}

.app-modal {
  background: #fff;
  padding: 30px 60px;
  border-radius: 5px;
  width: 500px;
  max-width: 90%;
  overflow-y: auto;
  box-shadow: 0 4px 15px 0 #00000010,
    4px 0 15px 0 #00000010,
    0 -4px 15px 0 #00000010,
    -4px 0 15px 0 #00000010;


  .title {
    font-size: 36px;
    font-weight: $fw-bold;
    @include accent-gradient-text;
  }


  .content {
    margin-bottom: 30px;

    .log {
      color: $color-text-label;
      span {
        color: $color-text-form-field;
        font-weight: $fw-semi-bold;
      }
    }

    .modal-separator {
      height: 1px;
      width: 100%;
      background-color: $color-separator-secondary;
    }
  }

  .modal-actions {
    display: flex;
    justify-content: center;
    gap: 40px;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 150px;
      height: 50px;
      border-radius: 5px;
      overflow: hidden;
      font-size: 16px;
      gap: 10px;
      font-weight: $fw-bold;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        filter: brightness(0.9);
      }

      &-cancel {
        justify-content: space-evenly;
        min-width: auto;
        border-radius: 5px;
        height: 30px;
        min-width: 120px;
        background-color: #33384C26;
        border: none;
        color: #244647;
        font-size: 14px;
        font-weight: $fw-semi-bold;
        box-shadow: 0px 4px 4px 0px #00000040;

        &:hover {
          background: #d6d6d6;
        }
      }

      &-confirm {
        justify-content: space-evenly;
        min-width: auto;
        border-radius: 5px;
        height: 30px;
        min-width: 120px;
        border: none;
        color: #fff;
        font-size: 14px;
        font-weight: $fw-semi-bold;
        box-shadow: 0px 4px 4px 0px #00000040;

      }
    }
  }
}