@import '../styles/utils.scss';

.info-disabled {
    box-sizing: border-box;
    height: 50px;
    padding: 0px 10px;
    border-radius: 5px;
    background: rgba(51, 56, 76, 0.15);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .label {
        font-size: 12px;
        font-weight: $fw-semi-bold;
        line-height: 15px;
        color: rgba(153, 153, 153, 1);

    }

    .value {
        color: rgba(36, 70, 71, 0.75);
        font-size: 16px;
        font-weight: $fw-bold;
        line-height: 22.5px;
    }
}