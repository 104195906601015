@import 'src/styles/utils';

.app-input-dropdown-container {
    max-width: 350px;
    width: 100%;
    position: relative;
    font-size: 16px;
    background: rgba(255, 255, 255, 1);

    .dropdown-header {
        display: flex;
        padding-left: 20px;
        padding-right: 20px;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: $fw-bold;
        color: $color-text-form-field;
        background: rgba(255, 255, 255, 1);
        ;
        box-shadow: 0px 4px 4px 0px #00000040;

        .app-input {
            width: 100%;
            font-family: inherit;
            font-size: 16px;
            background: transparent;
            border: none;
            outline: none;
            width: 100%;
            font-weight: $fw-bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &::placeholder {
                color: rgba(153, 153, 153, 1);
            }
        }
    }

    .dropdown-list {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        color: rgba(36, 70, 71, 1);
        background-color: #F9F9F9;
        border-radius: 5px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        max-height: 200px;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 8px;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ccc;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        .dropdown-option {
            padding: 10px 35px;
            cursor: pointer;
            transition: background-color 0.2s;

            &:hover {
                background-color: #EDEDED;
            }

            font-weight: $fw-semi-bold;
            transition: background-color 0.2s;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

        }
    }
}