@import '../../../../styles//mixins';


.mid-transactions-page {
    &.page-content {
        .page-header {

            .page-title {
                font-size: 32px;
            }

            .back-icon {
                cursor: pointer;
                transition: transform 0.3s ease, color 0.3s ease;

                &:hover {
                    transform: translateX(-5px);

                }

                &:active {
                    transform: translateX(-5px) scale(0.95);
                }
            }
        }

        .total-chart {
            @media #{$lt-500-devices} {
                flex-direction: column;
            }
        }

        .progress-bar-chart {
            height: 30px;

            .fill {
                height: 30px;
            }
        }

        .mid-total-value {
            font-size: 20px;
            font-weight: $fw-semi-bold;
            line-height: 30px;

            @include accent-gradient-text
        }
    }
}