@import '../styles/utils';

.table-container {
    background-color: #FFFFFF;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;


    @media #{$lt-1080-devices} {
        @include custom-scrollbar;
        overflow-x: scroll;
    }


    .custom-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        color: #e2e8f0;

        thead {
            tr {


                th {
                    opacity: 0.5px;
                    background: #E1E1E2;
                    padding: 18px;
                    font-weight: $fw-bold;
                    color: #282828;
                    font-size: 17px;
                    text-align: left;
                    box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.151);


                    &.secondary-header {
                        color: #979797;
                    }

                    @media #{$lt-1080-devices} {
                        padding: 12px;
                    }

                    &:first-child {
                        border: none;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }

                    &:last-child {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }
        }

        tbody {
            tr {

                td {
                    color: black;
                    font-weight: $fw-semi-bold;
                    padding: 18px;
                    font-size: 16px;

                    @media #{$lt-1080-devices} {
                        padding: 12px;
                    }

                    border-bottom: 1px solid #EFEFEF;
                }
            }
        }


        .legend-status-color {
            width: 31px;
            height: 13px;
            border-radius: 5px;
        }

        .legend-status-text {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.5px;

        }

        .gradient-accent-text {
            @include accent-gradient-text;
        }

        .secondary-text {
            font-size: 12px;
            font-weight: 500;

        }

        .truncated-text {
            display: inline-block;
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .link-text {
            color: #244647;
            font-weight: $fw-semi-bold;
            line-height: 16.5px;
            text-align: right;
            text-decoration-line: underline;
            cursor: pointer;
        }

        .id-text {
            display: inline-block;
            position: relative;
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
            text-align: left;
            text-decoration-line: underline;
            text-decoration-style: solid;
            cursor: pointer;

            &:hover {
                @include accent-gradient-text
            }
        }

        .date-text {
            color: #999999;
            font-size: 15px;
            font-weight: 600;
            line-height: 15px;
            text-align: center;
        }

        .status {
            width: 130px;
            color: rgba(255, 255, 255, 1);
            height: 25px;
            font-size: 13px;
            font-weight: $fw-bold;
            letter-spacing: 0.5px;
            text-align: center;
            border-radius: 5px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            cursor: pointer;


            &.approved {
                background: linear-gradient(90deg, #00B69B 44.5%, #038370 100%);
            }

            &.refunded {
                background: linear-gradient(90deg, #6A5CA8 0%, #3B316A 100%);
            }

            &.chargeback {
                background: linear-gradient(90deg, #32ADE6 0%, #1C6080 100%);
            }
            &.rdr {
                background: linear-gradient(90deg, #32ADE6 0%, #1C6080 100%);
            }

            &.pending {
                background: linear-gradient(90deg, #5C4033 0%, #3B2B20 100%);
            }

            &.error {
                background: linear-gradient(90deg, #EF1E1B 33%, #AE0D0A 100%);
            }

            &.declined {
                background: linear-gradient(90deg, #FF9500 29%, #D17C05 100%);
            }
        }

       

        .action {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            padding-right: 10px;
            color: rgba(255, 255, 255, 1);
            height: 25px;
            font-size: 13px;
            font-weight: $fw-bold;
            letter-spacing: 0.5px;
            border-radius: 5px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            cursor: pointer;
      
            transition: filter 0.3s ease, box-shadow 0.3s ease;
      
            &:hover {
              filter: brightness(1.15);
              box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.35); 
            }
      
            &.approved {
              background: linear-gradient(90deg, #00B69B 44.5%, #038370 100%);
            }
      
            &.refund {
              background: linear-gradient(90deg, #6A5CA8 0%, #3B316A 100%);
            }
      
            &.chargeback {
              background: linear-gradient(90deg, #32ADE6 0%, #1C6080 100%);
            }
      
            &.pending {
              background: linear-gradient(90deg, #5C4033 0%, #3B2B20 100%);
            }
      
            &.error {
              background: linear-gradient(90deg, #EF1E1B 33%, #AE0D0A 100%);
            }
      
            &.declined {
              background: linear-gradient(90deg, #FF9500 29%, #D17C05 100%);
            }
          }


        .initiate-refund {
            display: inline-block;
            position: relative;
            font-size: 14px;
            font-weight: $fw-semi-bold;
            line-height: 16.5px;
            text-align: center;
            -webkit-text-fill-color: transparent;
            cursor: no-drop;
            background: linear-gradient(90deg, #6A5CA8 0%, #3B316A 100%);
            -webkit-background-clip: text;
        }


        &.disabled {
            color: #EF1E1B;
            cursor: no-drop;
        }

        @keyframes rotate {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .refresh-icon {
            color: #00b69b;
            transition: transform 0.3s ease-in-out;
            cursor: pointer;

            &.rotate {
                animation: rotate 0.5s ease-in-out;
                color: #EF1E1B;
                cursor: no-drop;
            }
        }

        .refund-reason {
            white-space: pre-line; 
            display: -webkit-box;
            -webkit-line-clamp: 5; 
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}