@import '../styles/utils.scss';

.statistics-section {
    width: 100%;
    background-color: #fff;
    padding: 20px 20px 40px 20px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    box-sizing: border-box;

    .section-header {
        cursor: pointer;

        .title {
            font-size: 20px;
            font-weight: $fw-bold;
            color: #282828;
        }

        svg {
            transition: transform 0.3s ease; 
        }

        .arrow-icon.open {
            transform: rotate(180deg); 
        }
    }

    .section-content {
        max-height: 0;
        transition: max-height 0.3s ease, opacity 0.3s ease;
        opacity: 0;

        &.open {
        margin-top: 40px;

            max-height: 100%; 
            opacity: 1;
        }
    }
}
