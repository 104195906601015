@import '../../../../styles//utils.scss';

.report-daily-table {
  background-color: #FFFFFF;
  border-radius: 12px;

  @media #{$lt-1080-devices} {
    @include custom-scrollbar;
    overflow-x: auto;
  }

  table {
    width: 100%;
    color: #282828;
    font-size: 14px; 

    thead {
      tr {
        th {
          background: #E1E1E2;
          padding: 12px; 
          font-weight: $fw-bold;
          color: #282828;
          font-size: 14px; 
          text-align: center;
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.1);

          @media #{$lt-1080-devices} {
            padding: 8px; 
          }

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
    }

    tbody {
      tr {
       

        td {
          color: black;
          font-weight: $fw-semi-bold;
          padding: 10px; 
          font-size: 13px; 
          text-align: center;
          border-bottom: 1px solid #EFEFEF;

          @media #{$lt-1080-devices} {
            padding: 8px; 
          }
        }
      }
    }
  }

  .approved {
    color: rgb(0, 182, 155);
  }

  .declined {
    color: rgb(239, 30, 26);
  }
}
