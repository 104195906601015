.add-element-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border: 2px dotted #ccc; 
    border-radius: 5px; 
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #fff;

    &:hover {
        border-color: #aaa; 
        background-color: #f9f9f9; 
    }

    .plus-icon {
        font-size: 18px; 
        font-weight: bold;
        color: #888;
        margin-right: 5px; 
    }

    .button-text {
        font-size: 16px;
        font-weight: 500;
        color: #888;
    }
}
