@import '../styles/utils.scss';

.app-modal-transaction-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.app-modal {
  background: #fff;
  padding: 30px 60px;
  border-radius: 5px;
  width: 500px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 4px 15px 0 #00000010,
    4px 0 15px 0 #00000010,
    0 -4px 15px 0 #00000010,
    -4px 0 15px 0 #00000010;


  .modal-icon {
    margin-bottom: 20px;
  }

  .modal-content {
    margin-bottom: 30px;

    .modal-text {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 15px;
      line-height: 1.5;
      letter-spacing: -0.11px;
    }

    .modal-id {
      font-size: 14px;
      color: #888;
    }
  }

  .modal-actions {
    display: flex;
    justify-content: center;
    gap: 40px;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 150px;
      height: 50px;
      border-radius: 5px;
      overflow: hidden;
      font-size: 16px;
      gap: 10px;
      font-weight: $fw-bold;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        filter: brightness(0.9);
      }

      &-cancel {
        justify-content: space-evenly;
        min-width: auto;
        border-radius: 5px;
        height: 30px;
        min-width: 120px;
        background-color: #33384C26;
        border: none;
        color: #244647;
        font-size: 14px;
        font-weight: $fw-semi-bold;
        box-shadow: 0px 4px 4px 0px #00000040;

        &:hover {
          background: #d6d6d6;
        }
      }

      &-confirm {
        justify-content: space-evenly;
        min-width: auto;
        border-radius: 5px;
        height: 30px;
        min-width: 120px;
        border: none;
        color: #fff;
        font-size: 14px;
        font-weight: $fw-semi-bold;
        box-shadow: 0px 4px 4px 0px #00000040;

      }
    }
  }
}