@import '../styles/utils.scss';

.table-pagination-container {
    @media #{$lt-500-devices} {
        order: 1;
      }
    .pagination-info {
        @include accent-gradient-background;
        flex-wrap: nowrap;
        min-height: 30px;
        display: flex;
        padding: 0px 10px;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        font-size: 12px;
        font-weight: $fw-semi-bold;
        color: #FFFFFF;
        box-shadow: 0px 4px 4px 0px #00000040;
    }
}