@import '../styles/utils';

.side-menu {
    position: fixed;
    background: linear-gradient(266.15deg, #244647 5.85%, #218579 202.85%);
    min-width: 260px;
    min-height: 100vh;
    height: 100%;
    padding: 20px 0px;
    padding-left: 10px;
    overflow-x: auto;

    @media #{$lt-500-devices} {
        position: absolute;
        top: 0px;
        left: 0;
        height: 100%;
        width: 260px;
        z-index: 1000;
        background-color: $color-bg-main;
        transition: transform 0.3s ease;
        transform: translateX(-100%);

        &.open {
            width: 350px;
            transform: translateX(0);
        }
    }

    .user-info {

        .user-avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            font-weight: 800;
            font-size: 18px;
            background-color: white;
            color: $color-accent;
        }

        .user {
            max-width: 180px;
            gap: 5px;
            color: #dadcdf;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;

            span {
                font-weight: $fw-semi-bold;
            }
        }
    }

    .logo-container {
        padding-left: 5px;
        cursor: pointer;

        svg {
            max-width: 61px;
            max-height: 61px;
        }

        .logo {
            font-size: 24px;
            text-align: center;

        }
    }

    .menu-items {
        padding-left: 10px;

        .item {
            justify-content: start;
            align-items: center;
            color: #C2C3C5;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.30000001192092896px;
            cursor: pointer;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            transition: color 0.3s ease;

            &:hover {
                color: #ffffff;
                font-weight: 600;
            }

            &.active {
                color: rgba(255, 255, 255, 1);
                font-weight: 800;
            }
        }
    }

    .logout {
        padding-left: 10px;
        justify-content: start;
        align-items: center;
        color: #C2C3C5;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.30000001192092896px;
        cursor: pointer;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        transition: color 0.3s ease;

        &:hover {
            color: #ffffff;
            font-weight: 600;
        }
    }

    .clock {
        padding-left: 10px;
        justify-content: start;
        align-items: center;
        color: #C2C3C5;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.30000001192092896px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        transition: color 0.3s ease;
    }



    .hidden {
        visibility: hidden;
    }
}