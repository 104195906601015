/**
 * Entry point for all styles
 */

/**
 * Load external libs
 */
// icons
// @import '../assets/appIcon/style.scss';
.app-icon {
  // default icon size
  font-size: 32px;
}
// react-date-picker

/**
 * Load helper styles (classes, ids etc.)
 */
@import 'flexbox';
@import 'util-classes';