

.card-legend-item {
    background: rgba(235, 237, 240, 1);
    padding: 20px 20px 20px 20px;
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);


    &.hover-enabled:hover {
        transform: translateY(-5px); 
        box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.4); 
        cursor: pointer; 
    }
}