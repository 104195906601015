@import '../../../../styles/utils';

.add-merchant-form {

    .label {
        font-size: 15px;
        font-weight: 600;
        line-height: 22.5px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: $color-text-label;
    }


    .field-wrapper {
        width: 100%;
    
        &.hidden {
            visibility: hidden; 
            display: block; 
        }
    
        &.none {
            visibility: hidden; 
            display: none; 
        }
    }

}