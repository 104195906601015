

.table-filters {
    .app-input {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    
}