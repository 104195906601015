.dashboard-page-part-summary {
    width: 340px;
    padding: 20px;
    background: linear-gradient(270deg, #FFFFFF 45.5%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.5px;
    
    .description {
        color: rgba(36, 70, 71, 1);
        line-height: 23px;

        .value {
            font-weight: 700;
        }
    }

    .commission {
        color: rgba(255, 255, 255, 1);
        background: linear-gradient(266.15deg, #244647 5.85%, #218579 94.56%);
        width: 100%;
        height: 28px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        line-height: 10px;

        .value {
            font-size: 16px;
            font-weight: 700;
            line-height: 10px;
            text-align: center;

        }
    }
}