@import '../../styles/utils';

.auth-page-layout-container {
    height: 100vh;
    width: 100%;
    background: 
    radial-gradient(
      circle at center, 
      rgba(255, 255, 255, 0.4) 0%,  
      rgba(255, 255, 255, 0) 50%  
    ),
    url('../../assets/images/auth-bg.png');
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center;
   
    padding: 30px 30px;
    box-sizing: border-box;

    >* {
        max-width: 450px;
    }

    @media #{$lt-1080-devices} {
        justify-content: flex-start;
    }

    .header-container {

        .title-container {
            .title-text {
                font-size: 48px;
                font-weight: bold;
                color: #00B69B;


                text-shadow:
                    .5px 1.5px 2px #262B41,
                    -1.5px -1.5px 0px #262B41,
                    1.5px -.5px 0px #262B41,
                    -1.5px 1.5px 3px #262B41,
                    1.5px 1.5px 0px #262B41;

                letter-spacing: 1px;
            }

        }
    }
}