@import 'variables';

@mixin page-side-padding {
  padding-left: $layout-side-padding;
  padding-right: $layout-side-padding;

  @media #{$xs-devices} {
    padding-left: $layout-side-padding-sm;
    padding-right: $layout-side-padding-sm;
  }

  @media #{$lt-375-devices} {
    padding-left: $layout-side-padding-xxs;
    padding-right: $layout-side-padding-xxs;
  }
}


@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    height: 10px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #5D708B;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-accent;
    border-radius: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #00D6B6;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}


@mixin accent-gradient-background {
  background: linear-gradient(266.15deg, #244647 5.85%, #218579 94.56%);
}

@mixin accent-gradient-text {
  background: linear-gradient(266.15deg, #244647 5.85%, #218579 94.56%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
